.client-details-container
{
    margin-top: 10px;
    height: calc(100vh - 170px);
    overflow-y: scroll;
    overflow-x: hidden;
}
.client-detail
{
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 7px;
    width: 100%;
    background-color: #F6F6F6;
    border-radius: 5px;
}
.client-detail img{
    width: 23px;
}
.client-detail button{
    margin-top: 7px;
    padding: 0px;
}
.client-detail p
{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 15px;
    color: #89919B;
    padding: 0px;
    margin: 0px;
    
}
.client-detail .cn 
{
    font-family: 'Segoe UI';
    font-weight: 500;
}
.client-detail .ad 
{
   font-size: 15px;
}
.client-detail :hover
{
} 