
@page
{
}
body
{
    

    
}
.lgo
{
    width: 68.2%;
}
.txt
{
    font-weight: lighter;
}
.pg
{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 0px;
    width: 255mm;
    /* margin: auto; */
    background-color: white;
    box-shadow: 12 12 12 gray;
}
.dts
{
    padding: 0px;
    margin: 0px;
    margin-top: 45px;
}
.op
{
    padding: 0px;
    margin: 0px;
    font-family: Bebas;
    font-size: 40px;
    margin-top: 30px;
}
.hd img
{
    display: flex;
    height: 70px;
}
.tc
{
    text-align: center;
}
.pd
{
    padding-left: 5px;
}
.qte
{
    width: 60px
}
.big
{
    width: 100%;
    text-align: center;
    font-weight: bold;
    padding: 0px;
    margin: 0px;
    font-size: 20px;
}
.wd
{
    width: 100px;
}
.cnt
{
    width:57%
}
.gr
{
    -webkit-print-color-adjust: exact !important;
    background-color: rgba(228, 226, 226, 0.808);
}
.und
{
}
.small
{
    text-align: center;
    font-weight: lighter;
    padding: 0px;
    margin: 0px;
    font-size: 12px;
}
.table-invoice-header
{
    font-size: 20px;
    width: 100%;
    font-weight: bold;
}
.table-invoice-details
{
    margin-top: 20px;
    font-size: 20px;
    border-collapse: collapse;
    width: 100%;
    border: 1px solid black;
}
.table-invoice-details tbody tr 
{
height: 39px;
}
.table-invoice-details tbody tr :first-child
{
    text-align: center;
    width: 40px;
}

.table-invoice-details  td,th
{
    border-bottom: 1px dashed gray;
    border-left: 1px solid black;
    border-right: 1px solid black;
}
.table-invoice-details thead tr{
    font-weight: bold;
    border-bottom: 1px solid black;
}
.table-invoice-footer
{
    margin-top: 30px;
    font-size: 20px;
    width: 100%;
    font-weight: bold;
}