.nav-header-title
{
    color: #DD0E2D;
    font-weight: bold;
    font-size: 40px;
}
.gray-control
{
    background-color: #F6F6F6;
}
.rounded
{
    border-radius: 15px;
}
.borderless
{
    border: 0px;
}

.table-actions td:last-child
{
    text-align: right;
}
.table-actions td button,a:last-child
{
    margin-left: 15px;
}
.no-pointer{
    pointer-events: none;
}
.gradian
{
    color : white;
    background-image: linear-gradient(to right, #961120 , #E81A31);;
    
    font-weight: bold;
}
.to-right
{
    text-align: end;
}
.to-center
{
    text-align: center;
}
.to-left
{
    text-align: start;
}
.rs-checkbox-wrapper
{
    background-color: white;
}

::-webkit-scrollbar {
    width: 3px;
  }
::-webkit-scrollbar-track {
    background: white;
  }
  
  /* Handle */
::-webkit-scrollbar-thumb {
    background: #d4d3d3;
  }
  
  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background:#d4d3d3;
  }
  .white-text
  {
      color:white
  }
body{
    position: absolute;
    width: 100%;
    height: 100vh;
    min-width: 1024px;
}
  body *
{
    outline: none;
}
*:focus
{
    outline: none;
}
.button-with-image
{
text-align: start;
padding-left: 50px;
}
.button-with-image img
{
    position: absolute;
    width: 25px;
    left: 10px;
}
.to-ellipsis
{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.rs-container
{
    /* width : 500px; */
}
.rs-check-tree-node-expand-icon-wrapper>.rs-check-tree-node-expand-icon
{
    padding: 0px;
    margin-top: 15px;
    margin-left: 15px;
    height: 10px;
}
.rs-check-tree-node-expand-icon-wrapper>.rs-check-tree-node-expanded, .rs-check-tree-open>.rs-check-tree-node .rs-check-tree-node-expand-icon-wrapper>.rs-check-tree-node-expand-icon {
margin-left: -5px;
margin-top: 10px;
}
.rs-check-tree .rs-check-item .rs-checkbox-checker>label:before {
    background-color: #F1F1F1;
}
