.column-day
{
    color : #89919B;
    
    padding-left: 15px;
    padding-right: 15px;
    background-color: #F8F8F8;
    border-radius: 15px;
    margin-bottom: 20px;
}
.column-day h5
{
    font-size: 16px;
    padding-top: 10px;
    
}
.column-day .rs-col
{   
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
}
.day-status
{
    background-color: red;
}
.column-header
{
    padding-left: 5px;
    padding-right: 10px;
    margin-bottom: 10px;
}
.column-header button
{
    margin-top: -5px;
    padding: 0px;
}
.column-header p
{
    color : #89919B;
    font-weight: 600;
}
.column-header img
{
    background-color: white;
    width: 20px;
}
.column-body
{
    padding-left: 5px;
    padding-right: 10px;
    position: absolute;
    width: 95%;
    height: calc(100vh - 300px);
    overflow-y: scroll;
    overflow-x: hidden;
}
.column-body div
{
    padding-left: 10px;
   border-left: 4px solid #F8F8F8;;
   margin-bottom: 15px;
}
.column-body p:first-child
{
    font-size: 13px;
    color: #0CA7B2;
    font-weight: 600;
}
.column-body h6 {
    font-size: 13px;
    color: #89919B;
    font-weight: 600;
}
.column-body p:last-child
{
    color: #89919B;
}
.column-body::-webkit-scrollbar {
    width: 3px;
  }
  .column-body::-webkit-scrollbar-track {
    background: white;
  }
  
  /* Handle */
  .column-body::-webkit-scrollbar-thumb {
    background: #f0eeee;
  }
  
  /* Handle on hover */
  .column-body::-webkit-scrollbar-thumb:hover {
    background:#f0eeee;
  }