.day-nav-button
{
    padding-top: 15;
    margin-right: 5px;
    margin-left: 5px;
    color : #89919B;
    background-color: white;
    font-weight: 600;
    
    font-size: 16px;
}

.day-left-nav-button img
{
    margin-right: 10px;
    width: 15px;
}
.day-right-nav-button img
{
    margin-left: 10px;
    width: 15px;
}