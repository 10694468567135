.filter-appointment
{
    margin-top: 15px;
    background-color: #F1F1F1;
    height: 45px;
   padding: 7px;
   padding-top: 7px;
}
.filter-appointment-empty{
    width: 10px;
}
.filter-state button
{
    height: 30px;
}
.filter-appointment button
{
   color: #89919B;
   font-size: 15x;
   background-color: white;
}
.filter-margin-right
{
    padding-right: 10px;
}

.rs-picker-default .rs-btn, .rs-picker-default .rs-picker-toggle, .rs-picker-input .rs-btn, .rs-picker-input .rs-picker-toggle
{
    background-color: red;
}