@font-face {
  font-family: Bebas;
  src: local('Bebas'), url('fonts/bebas/Bebas-Regular.ttf') format('truetype'), url('fonts//bebas/Bebas-Regular.otf') format('opentype');
}
/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
