.solved-table-header
{
  height: 40px;
  padding-top: 7px;
  margin-top: 10px;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 10px;
  background-color: #F1F1F1;
  font-size: 15px;
  color: #B3B8BD;
}
.solved-table-header .rs-checkbox-checker
{
margin-top: -4px;
margin-left: -1px;
}