.client-history
{
    position: absolute;
    margin-top: 22px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: calc(100% - 20px);
    height: calc(100vh - 170px);
    background-color: #F6F6F6;
    border-radius: 5px;
    color: #89919B;
}
.client-history-print
{
    position: absolute;
    right: 10px;
    top: 20px;
}
.client-history-detail
{
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
}
.client-history-detail tbody td
{
    padding-top: 7px;
}
.client-history-detail p
{
    border-bottom: 2px solid #c7cccc;
    padding-bottom: 5px;
    font-size: 15px;
}
.client-history tbody td:first-child
{
    font-weight: 600;
    width: 100px;
}