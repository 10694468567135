.solved-table-container{
  min-width: 300px;
    /* margin-left: -5px;
    margin-bottom: -5px; */
    width: 100%;
    height: calc(100vh - 280px);
    overflow-y: scroll;
    overflow-x: hidden;
    /* -ms-overflow-style:none;
    scrollbar-width: 3px;   */
    
}
/* .table-container::-webkit-scrollbar {
    width: 0px;
    display:contents;
} */
.solved-table-detail
{
  height: 50px;
  padding-right: 5px;
  padding-top: 13px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 0px;
  background-color: #F6F6F6;
  font-size: 15px;
  color: #89919B;
}
.solved-table-detail .rs-checkbox-wrapper
{
 background-color: white;
}
.solved-table-detail-checker
{
  margin-top: -5px;
}
.solved-table-detail-client-name
{
  font-weight: 600;
  color :#89919B;
  
}
.solved-table-detail-phone
{
    color :#6E7277;
    font-weight: 600;
}
.solved-table-detail-state
{
    background-color: white;
    margin-top: -5px;
    font-weight: 600;
    border-radius: 10px;
    border: 1px solid #f7f6f6;
    box-shadow: 2px 12px 12px #ebebeb;
    -webkit-box-shadow: 2px 5px 12px #dad8d8;
    -moz-box-shadow: 2px 12px 12px #ebebeb;
}