
.side-bar .rs-sidebar
{
  flex: none;
}
aside{
    flex: none;
    width: 400px;
}
.side-bar
{
    flex: none;
    background-color: #F6F6F6;
    height: 100vh;
    width: 500px;

}
.side-bar-logo
{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 100px;
}
.side-bar-logo svg
{
  width: 100%;
}
.side-bar-body
{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.side-bar-body img
{
  width: 25px;
  margin-left: 5px;
  margin-right: 15px;
}
.side-bar-body button
{
  background-color: white;
  border-radius: 15px;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 600;
  text-align: start;
  padding-left: 10px;
  height: 60px;
  box-shadow: 0px 5px 15px #ebebeb;
  -webkit-box-shadow: 2px 12px 12px #ebebeb;
  -moz-box-shadow: 0px 5px 15px #ebebeb;

}
.side-bar-body button:focus
{
color : white;

}
.side-bar-body button:hover
{
  color : white;
}

.side-bar-footer
{
   position: relative;
  width: 80%;
  bottom: 0px;
  left: 0px;
  
  margin-left: auto;
  margin-right: auto;
}
.side-bar-footer button
{
  background-color: white;
  border-radius: 15px;
  margin-bottom: 10px;
  /* color: #89919B; */
  font-size: 22px;
  font-weight: 600;
  text-align: start;
  padding-left: 10px;
  height: 60px;
  box-shadow: 0px 5px 15px #ebebeb;
  -webkit-box-shadow: 2px 12px 12px #ebebeb;
  -moz-box-shadow: 0px 5px 15px #ebebeb;
}
.side-bar-footer img
{
  width: 25px;
  margin-left: 5px;
  margin-right: 15px;
}
.slider-div {
  margin-left: 0px;
  text-align: end;
  right: 0px;
}
.slider-div button
{
  position:fixed;
  margin-top: 20px;
  margin-left: -15px;
  width: 25px;
  height: 25px;
}
.slider-div svg
{
  position: absolute;
    left: 0px;
    top: 5px;
}