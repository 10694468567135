.custom-modal .rs-modal-content
{
 background-color: #F1F1F1;   
}

.custom-modal *
{
    border-radius: 10px;
    /* color: #67717E; */
    /* font-size: 20px; */
}

.custom-modal .rs-form:not(.rs-form-inline) .rs-form-group:not(:last-child)
{margin-bottom: 5px;

}
.rs-form-group
{
    border-radius: 0px;
    
}
.custom-modal .rs-rate .rs-rate-md .rs-rate-red .rs-rate-character .rs-rate-character-full
{
    color : red
}
/* .custom-modal input,.rs-picker-toggle .rs-btn .rs-btn-default{
    padding: 10px;
} */