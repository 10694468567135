.side-nav
{
    background: #F6F6F6;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 20%;
}
.side-nav-header
{
    width: 85%;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
}
.side-nav-header img{
    width: 100%;
}
.side-nav-body
{
    width: 85%;
    margin-top: 30%;
    margin-left: auto;
    margin-right: auto;
}
.side-nav-body button
{
    border: 0px;
    color: #89919B;
    background-color: white;
    border-radius: 10px;
    font-size: 22px;
    height: 60px;
    margin-bottom: 20px;
    box-shadow: 0px 5px 15px #ebebeb;
    -webkit-box-shadow: 2px 12px 12px #ebebeb;
    -moz-box-shadow: 0px 5px 15px #ebebeb;
}
.side-nav-body button img
{
    position:absolute;
    width: 30px;
    margin-top: auto;
    margin-bottom: auto;
    left: 15%;
}