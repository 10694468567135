.print-client-history
{
    /* position: absolute; */
    /* margin-top: 22px; */
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    /* height: 78vh; */
    /* background-color: #F6F6F6; */
    border-radius: 5px;
    color: #89919B;
}
.print-client-history-print
{
    position: absolute;
    right: 10px;
    top: 20px;
}
.print-client-history-detail
{
    width: 100%;
    /* padding-left: 30px;
    padding-right: 30px; */
    padding-top: 13px;
}
.print-client-history-detail tbody td
{
    padding-top: 7px;
}
.print-client-history-detail p
{
    border-bottom: 2px solid #c7cccc;
    padding-bottom: 5px;
    font-size: 15px;
}
.print-client-history tbody td:first-child
{
    font-weight: 600;
    width: 100px;
}