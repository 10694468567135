.header-div
{
    height: 85px;
    margin-left: 30px;
    margin-right: 30px;
    border-bottom: 2px solid #F6F6F6;
}
.header-body
{
    margin-top: 20px;
}
.page-title
{
    font-family: Bebas;
    font-size: 50px;
    text-transform: uppercase;
    color: #DD0E2D;
    font-weight: 500;
}
.header-side-button
{
    margin-top: 5px;
    width: 45px;
    height: 45px;
    background-color: white;
}
.header-side-button
{
    padding: 0px;
}
.header-side-button img
{
    width: 25px;
}
.home-search
{
    font-size: 30px;
    margin-top: 10px;
    border: 0px;
    border-radius: 15px;
}
.home-search input
{
    border: 0px;
    border-radius: 15px;
}

