.typeAppointment-table-container{
  min-width: 300px;
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden; 
  width: 100%;
  height: 75%; 
    
}
/* .table-container::-webkit-scrollbar {
    width: 0px;
    display:contents;
} */
.typeAppointment-table-detail
{
  height: 40px;
  padding-right: 5px;
  padding-top: 9px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 0px;
  background-color: #F6F6F6;
  font-size: 15px;
  color: #89919B;
}
.typeAppointment-table-detail .rs-checkbox-wrapper
{
 background-color: white;
}
.typeAppointment-table-detail-checker
{
  margin-top: -7px;
}
.typeAppointment-table-detail-client-name
{
  font-weight: 600;
  color :#89919B;
  
}
.typeAppointment-table-detail-phone
{
    color :#6E7277;
    font-weight: 600;
}
.typeAppointment-table-detail-state
{
    background-color: white;
    margin-top: -5px;
    font-weight: 600;
    border-radius: 10px;
    border: 1px solid #f7f6f6;
    box-shadow: 2px 12px 12px #ebebeb;
    -webkit-box-shadow: 2px 5px 12px #dad8d8;
    -moz-box-shadow: 2px 12px 12px #ebebeb;
}