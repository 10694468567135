.group-tool img,svg
{
    width: 25px;
}
.group-tool button
{
    background-color: #F1F1F1;
    border-radius: 0px;
    width: 50px;
}
.group-tool-body svg
{
    margin-top: 4px;
    margin-bottom: 5px;
}
/* .group-tool 
{
    padding-top: 5px;
    padding-left: 10px;
    background-color: #F1F1F1;
    border-radius: 0px;
    height: 45px;
    width: 50px;
} */
.group-tool :first-child
{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.group-tool button:last-child
{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}