.printing-table
{
    border: 1px solid silver;
    width: 100%;
}
.table-actions td button, a:last-child {
    margin-left: 0px;
}
.printing-table tbody td:first-child
{
    width: 20px;
}
.printing-table thead td:first-child
{
   width: 20px;
}
.printing-table-date
{
    width: 160px;
}
.printing-table td
{
    padding: 2px;
    border: 1px solid silver;
}
.printing-table tr
{
    border: 1px solid silver;
}
.printing-table thead
{
    font-weight: bold;
}