.notFound-form
{
    margin:auto;
    margin-top: 8%;
    height: 100%;
    width: 400px;
    
}
.notFound-form img
{
    width: 100%;
    margin-bottom: 50px;
}
.notFound-div
{

    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100vh;
}
.notFound-div-corners img  {
    width: 700px;
}
.notFound-div-corners img:first-child
{
    position: absolute;
    top: -350px;
    left: -370px;

}
.notFound-div-corners img:last-child
{
    position: absolute;
    right: -370px;
    bottom: -350px;
}