.add-button
{
    /* max-width: 150px; */
    padding-top: 7px;
    height: 45px;
    font-size: 17px;
    border-radius: 10px;
 background-image: linear-gradient(to right, #961120 , #E81A31);;
}
.add-button img
{
    width: 25px;
}