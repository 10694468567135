.custom-timeline {
    margin-left: 20px;
  }
  .custom-timeline svg{
    margin-left: 20px;
  }
  .custom-timeline td
  {
    color: #999;
  }
  .custom-timeline td:first-child{
    width: 190px;
    font-weight: bold;
    color: #999;
  }
  .custom-timeline p{
    margin: 0px;
    padding: 0px;
    font-weight: bold;
  }
  .custom-timeline .rs-timeline-item-custom-dot .rs-icon {
    position: absolute;
    background: #fff;
    top: 0;
    left: -2px;
    border: 2px solid #ddd;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 18px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #999;
    margin-left: -13px;
  }
  
  .custom-timeline .rs-timeline-item-content {
    margin-left: 24px;
  }